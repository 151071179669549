import React, {useContext} from 'react';
import Layout from "./layout";
import SEO from "./seo";
import { Floater } from './floater';
import Wallpaper from './wallpaper';
import UiStoreContext from "../contexts/uiStoreContext"
import "twin.macro"
import Icon from './icon'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export const ErrorPage = ({ title, details, displayAds=true }) => { 

	const uiStore = useContext(UiStoreContext)
	uiStore.setDisplayAds(displayAds)

	return <>
		<Wallpaper />
		<Layout footer={false} fullWidth={true}>
			<Floater>
				<div tw="p-4 h-104 sm:h-auto">
					<div tw="text-2xl sm:text-xl text-blue flex items-center justify-center"><Icon icon={faExclamationCircle} tw="h-6 inline mr-2" /> {title}</div>
					<div tw="text-base p-4 text-center">{details}</div>
				</div>
			</Floater>
			<SEO title={title} noIndex />
		</Layout>
	</>
}
